import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@utils/models/ApiResponse';
import { environment } from 'environments/environment';
import { sortBy } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, take, toArray } from 'rxjs/operators';
import { TbTransportista } from '~models/maestros/TbTransportista';
import { CustomTbTransportistaEntidad } from '~models/maestros/custom/CustomTbTransportistaEntidadDto';
import { SearchTbTransportistaDto } from '~models/maestros/search/SearchTbTransportistaDto';
import { NgSelect } from '~shared/interface/NgSelect.interface';

const url = `${environment.HOST_MAESTROS}/tbtransportistas`;

@Injectable({
	providedIn: 'root',
})
export class TbTransportistasService {
	private _dataTransportistaSubject: BehaviorSubject<CustomTbTransportistaEntidad> = new BehaviorSubject<CustomTbTransportistaEntidad>(null);
	globalTransportistaData$: Observable<CustomTbTransportistaEntidad> = this._dataTransportistaSubject.asObservable();

	private _dataSelectBySearch$!: Observable<NgSelect[]>;

	constructor(private http: HttpClient) {}

	findAllByNroDocumentoCustom(nroDocumento: string): void {
		this.http
			.get<ApiResponse<CustomTbTransportistaEntidad>>(`${url}/model-se-ro/findAllByNroDocumentoCustom/${nroDocumento}`)
			.pipe(
				map((res) => res.data),
				take(1)
			)
			.subscribe((data) => {
				this._dataTransportistaSubject.next(data);
			});
	}

	findAllBySearch(search: SearchTbTransportistaDto): Observable<TbTransportista[]> {
		return this.http.post<ApiResponse<TbTransportista[]>>(`${url}/model-se-ro/findAllBySearch`, search).pipe(map((res) => res.data));
	}

	ngSelectBySearch(search: SearchTbTransportistaDto, reload = false): Observable<NgSelect[]> {
		if (!this._dataSelectBySearch$ || reload) {
			this._dataSelectBySearch$ = this.findAllBySearch(search).pipe(
				mergeMap((data: TbTransportista[]) => {
					return sortBy(
						data.map((transportista) => {
							return this.convertToNgSelect(transportista);
						}),
						'label'
					) as NgSelect[];
				}),
				toArray(),
				shareReplay(1)
			);
		}
		return this._dataSelectBySearch$;
	}

	convertToNgSelect(transportista: TbTransportista): NgSelect {
		return { ...transportista, value: transportista.idTransportista, label: `${transportista.codigo} - ${transportista.tbEntidad?.razonSocial}` };
	}
}
