export class ValidateUtil {
	// messageUtilService = new MessageUtilService();

	public static requerido(obj: any, nombre: string) {
		if (typeof obj == 'undefined' || obj == 'Invalid date' || obj == null) {
			throw nombre;
		}
	}

	public static isUndefinedOrNull(obj: any): boolean {
		if (typeof obj == 'undefined' || obj == 'Invalid date' || obj == null) {
			return true;
		}

		if (typeof obj == 'string' && obj == '') {
			return true;
		}

		return false;
	}
}
