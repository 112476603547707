import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, toArray, mergeMap, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TbEntidadLocal } from '~models/maestros/TbEntidadLocal';
import { TbUbicacionGeografica } from '~models/maestros-sync/maestros/TbUbicacionGeografica';
import { NgSelect } from '~shared/interface/NgSelect.interface';
import { ApiResponse } from '@utils/models/ApiResponse';

const url = `${environment.HOST_MAESTROS}/tbentidadlocales`;

@Injectable({
	providedIn: 'root',
})
export class TbEntidadLocalesService {
	private _dataSelectByIdEntidad$?: Observable<NgSelect[]>;

	constructor(private http: HttpClient) {}

	findAllByIdEntidadAndEstado(idEntidad: number, estado: boolean): Observable<TbEntidadLocal[]> {
		return this.http.get<ApiResponse<TbEntidadLocal[]>>(`${url}/model-se-ro/findAllByIdEntidadAndEstado/${idEntidad}/${estado}`).pipe(map((res) => res.data));
	}

	getNgSelectByIdEntidad(idEntidad: number, reload = false): Observable<NgSelect[]> {
		if (!this._dataSelectByIdEntidad$ || reload) {
			this._dataSelectByIdEntidad$ = this.findAllByIdEntidadAndEstado(idEntidad, true).pipe(
				mergeMap((data: TbEntidadLocal[]) => {
					return data.map((entidadLocal) => {
						return this.convertToNgSelect(entidadLocal);
					});
				}),
				toArray(),
				shareReplay(1)
			);
		}
		return this._dataSelectByIdEntidad$;
	}

	getNombreUbicacionGeograficaByNivel(nivel: number, tbUbicacionGeografica: TbUbicacionGeografica | null): string | null {
		let cad = '';
		if (tbUbicacionGeografica == null) return null;
		if (nivel >= 1) {
			const nombreNivel1 = tbUbicacionGeografica.nombre ? tbUbicacionGeografica.nombre : '';
			cad = nombreNivel1;
		}
		if (nivel >= 2) {
			const nombreNivel2 = tbUbicacionGeografica.idUbigeoPadre?.nombre ? tbUbicacionGeografica.idUbigeoPadre.nombre : '';
			if (cad == '') {
				cad += nombreNivel2;
			} else {
				cad += nombreNivel2 != '' ? ' - ' + nombreNivel2 : cad;
			}
		}
		if (nivel >= 3) {
			const nombreNivel3 = tbUbicacionGeografica.idUbigeoPadre?.idUbigeoPadre?.nombre ? tbUbicacionGeografica.idUbigeoPadre.idUbigeoPadre.nombre : '';
			if (cad == '') {
				cad += nombreNivel3;
			} else {
				cad += nombreNivel3 != '' ? ' - ' + nombreNivel3 : cad;
			}
		}
		return cad;
	}

	findAllByIdEntidad(idEntidad: number): Observable<TbEntidadLocal[]> {
		return this.http.get<ApiResponse<TbEntidadLocal[]>>(`${url}/model-se-ro/findAllByIdEntidad/${idEntidad}`).pipe(map((res) => res.data));
	}

	convertToNgSelect(entidadLocal: TbEntidadLocal): NgSelect {
		return {
			...entidadLocal,
			label: `${entidadLocal.codigo} - ${entidadLocal.direccion}`,
			value: entidadLocal.idEntidadLocal,
		};
	}
}
