import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, toArray } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NgSelect } from '~shared/interface/NgSelect.interface';
import { TbUbicacionGeografica } from '~models/maestros-sync/maestros/TbUbicacionGeografica';
import { ApiResponse } from '@utils/models/ApiResponse';

const url = `${environment.HOST_MAESTROS_SYNC}/tbubicaciongeograficas`;

@Injectable({
	providedIn: 'root',
})
export class TbUbicacionGeograficaService {
	dataUbiGeoSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	globalUbicacionGeograficaData$: Observable<string> = this.dataUbiGeoSubject.asObservable();

	private _dataSelect$?: Observable<NgSelect[]>;

	constructor(protected http: HttpClient) {}

	findAllByNivelAndEstadoNgSelect(nivel: number, estado: boolean): Observable<TbUbicacionGeografica[]> {
		return this.http.get<ApiResponse<TbUbicacionGeografica[]>>(`${url}/findAllByNivelAndEstadoNgSelect/${nivel}/${estado}`).pipe(map((res) => res.data));
	}

	getNgSelectNivel3(): Observable<NgSelect[]> {
		if (!this._dataSelect$) {
			this._dataSelect$ = this.findAllByNivelAndEstadoNgSelect(3, true).pipe(
				mergeMap((data: TbUbicacionGeografica[]) => {
					return data.map((res) => {
						return { ...res, label: `${res.codigo} - ${res.idUbigeoPadre?.idUbigeoPadre?.nombre}, ${res.idUbigeoPadre?.nombre}, ${res.nombre}`, value: res.idUbicacionGeografica };
					});
				}),
				toArray(),
				shareReplay(1)
			);
		}
		return this._dataSelect$;
	}

	convertToNgSelectSimple(res: TbUbicacionGeografica): NgSelect {
		return { ...res, label: `${res.codigo} - ${res.idUbigeoPadre?.idUbigeoPadre?.nombre}, ${res.idUbigeoPadre?.nombre}, ${res.nombre}`, value: res.idUbicacionGeografica };
	}
}
